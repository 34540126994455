@use 'mixins' as *;

.cta-button {
  --button-text-color: var(--color-white);
  --button-text-color-hover: var(--color-black);
  --button-bg-color: var(--color-cta-blue);
  --button-bg-color-hover: transparent;
  --button-block-size: var(--spacing-medium);
  --button-min-inline-size: 147px;
  --button-padding-block: var(--spacing-xsmall) var(--spacing-xregular);
  --button-margin-block: var(--spacing-regular);

  @include hover {
    &::after {
      content: '';
      position: absolute;
      inline-size: 100%;
      block-size: 100%;
      inset: 0;
      border: 2px solid var(--color-cta-blue);
      border-radius: 50px;
      mask-image: linear-gradient(
        0deg,
        white 16px,
        transparent 16px,
        transparent 32px,
        white 16px
      );
    }
  }
}
