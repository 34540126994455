@use 'mixins' as *;
@use 'sass:color';

.video-player {
  --media-object-fit: cover;
  --media-object-position: center;
  --controls: none;
  --dialog: none; /* Hide the error dialog */
}

.video-wrapper {
  position: absolute;
  inset: 0;
}

.inner {
  position: relative;
  z-index: 1;

  --content-width-narrow: var(--content-width-medium);
  .has-color & {
    --content-width-narrow: 41.25rem;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: inherit;
    background-color: var(--box-color);
    padding: var(--spacing-regular);

    @include breakpoint-min(small) {
      padding: var(--spacing-large);
    }
  }
}

.hero {
  display: flex;
  align-items: center;
  inline-size: 100%;
  position: relative;
  justify-content: center;
  background-color: var(--background-color, var(--color-black));
  background-color: hsl(
    from var(--background-color, var(--color-black)) h s 10%
  );

  &--full-width {
    overflow-y: hidden;
    max-block-size: 685px;
  }

  img {
    opacity: 1;
  }
}

.content {
  inline-size: 100%;
  block-size: 100%;
  max-inline-size: 100rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &--full-width {
    max-inline-size: 100%;
  }

  @include portrait {
    aspect-ratio: 3 / 4;
  }

  @include landscape {
    aspect-ratio: 21 / 9;
  }
}

.grid {
  align-items: center;
  justify-content: center;
  block-size: 100%;
}

.title {
  max-inline-size: 20ch;
  text-shadow: 0 0 0.25ch var(--color-text-shadow-black);
}

.description {
  max-inline-size: 56ch;
}

.button-container {
  display: flex;
  margin-block-start: var(--spacing-medium);
  flex-direction: column;

  @include breakpoint-min(small) {
    gap: var(--spacing-regular);
    flex-direction: row;
  }
}

.button {
  --button-margin-block: var(--spacing-xsmall);
}
